import Vue from "vue"
import Router from "vue-router"
import store from './store'

import OperationRegister from "./components/OperationRegister"
import OperationInfo from "./components/OperationInfo"
import FileList from "./components/Program/Files/FileList"
import Envelope from "./components/Envelope"
import EnvelopeList from "./components/EnvelopeList"
import PlatformBilling from "./components/Platform/PlatformBilling"
import AssetList from "./components/Assets/AssetList"
import TradeList from "./components/TradeList"
import NotificationList from "./components/NotificationList"
import ReceiptList from "./components/Receipts/ReceiptList"
import MembersList from "./components/Program/MembersList"
import PlatformConfiguration from "./components/Platform/PlatformConfiguration/PlatformConfiguration"
import EnvelopeTemplateList from "./components/Platform/PlatformConfiguration/EnvelopeTemplateList"
import PlatformOrganization
  from "./components/Platform/OrganizationProfile/PlatformOrganization"
import PlatformOrganizationList
  from "./components/Platform/PlatformOrganizationList"
import BankAccounts
  from "./components/Platform/OrganizationProfile/BankAccounts"
import DocumentTemplate
  from "./components/Platform/OrganizationProfile/DocumentTemplate"
import PartnersTemplate
  from "./components/Platform/OrganizationProfile/PartnersTemplate"
import ConnectionConfiguration
  from "./components/Platform/OrganizationProfile/ConnectionConfiguration"
import PlatformPersonalData from "./components/Platform/PlatformPersonalData"
import PlatformHome from "./components/Platform/PlatformHome"
import OrganizationProfile
  from "./components/Platform/OrganizationProfile/OrganizationProfile"
import CreateProgramSteps
  from './components/Platform/CreateProgram/CreateProgramSteps'
import PlatformConfigureProgram
  from './components/Platform/CreateProgram/PlatformConfigureProgram'
import Error500 from './components/Error500'
import PostingList from './components/Program/PostingList'
import DashboardHome from './components/Program/DashboardHome'
import AssumeProgramRole from './components/Program/Roles/AssumeProgramRole'
import PlatformPermissions from './components/Platform/PlatformPermissions'
import ConfigurationSchemaModal from './components/Platform/ConfigurationSchemaModal'
import PlatformCustomers from './components/Platform/PlatformCustomers'
import ExecutionsList from "./components/Program/ExecutionsList"
import ExecutionDetail from './components/Program/ExecutionDetail'

const platformConfig = {
  theHeader: {
    title: "Minha Conta",
    extraMenu: true
  },
  theNavigation: {
    menu: [{
      name: "",
      itens: [
        {
          icon: "home",
          text: "Home",
          execute: "/platform-home",
        },
        {
          icon: "account_circle",
          text: "Minha Conta",
          execute: "/platform-personal-data",
        },
        {
          icon: "insert_chart_outlined",
          text: "Dashboards",
          execute: "/dashboard",
          permissions: ["!sales"]
        },
        {
          icon: "assignment_turned_in",
          text: "Formalizações",
          execute: "/envelopes",
        },
        {
          icon: "monetization_on",
          text: "Faturamento",
          execute: "/billing",
          permissions: ["!sales", "!admin"]
        },
        {
          icon: "settings",
          text: "Configurações",
          execute: "/platform-configuration",
          permissions: ["admin"]
        },
        {
          icon: "mdi-account-plus",
          text: "Clientes",
          execute: "/customers",
          permissions: ["sales"]
        }
      ]
    }],
    navigationContext: 'platform'
  }
}

const platformTheme = {
  theHeader: {
    useBoxShadow: false,
    backgroundColor: '#f8f8f8'
  },
  theNavigation: {
    backgroundColor: '#f8f8f8'
  }
}

const programConfig = {
  theHeader: {
    title: "",
    extraMenu: false
  },
  theNavigation: {
    menu: [
      {
        itens: [{
          icon: "folder",
          text: "Meus arquivos",
          execute: "/files",
          permissions: ["trading-partner", "owner", "buyer", "seller"]
        }]
      },
      {
        name: "Movimentos",
        itens: [
          {
            icon: "request_quote",
            text: "Ativos",
            execute: "/assets",
            permissions: ["buyer","seller", "owner"],
          },
          {
            icon: "published_with_changes",
            text: "Negociações",
            execute: "/trades",
            permissions: ["seller", "buyer", "trading-partner", "owner"]
          }
        ]
      },
      {
        name: "Recebimentos",
        itens: [
          {
            icon: "inventory",
            text: "MIGO / MIRO",
            execute: "/receipts",
            permissions: ["owner"],
          }
        ]
      },
      {
        name: "Contábil",
        itens: [{
          icon: "calculate",
          text: "Lançamentos",
          execute: "/postings",
          permissions: ["admin"]
        }]
      },
      {
        name: "Operações",
        itens: [
          {
            icon: "remove_red_eye",
            text: "Consultar",
            execute: "/executions",
            permissions: ["owner"]
          },
        ]
      },
      {
        footerMenu: true,
        name: "Administrar programa",
        itens: [
          {
            icon: "people",
            text: "Participantes",
            execute: "/members",
          },
          {
            icon: "remove_red_eye",
            text: "Monitoramento",
            execute: "/operation-register",
            permissions: ["owner", "admin"]
          },
          {
            icon: "settings",
            text: "Configurações",
            execute: "/program-configuration",
            permissions: ["admin"]
          }
        ]
      }
    ],
    navigationContext: 'program'
  }
}

const programTheme = {
  theHeader: {
    backgroundColor: '#ffffff',
    useBoxShadow: true
  },
  theNavigation: {
    backgroundColor: '#f0f0f0'
  }
}

const platformOrganizationBreadCrumb =
  {
    breadcrumb: {
      paths: [
        {
          path: '/platform-organizations',
          name: 'Organizações'
        },
        {
          path: '',
          name: 'Perfil'
        }
      ]
    }
  }

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/platform-home'
    },
    {
      path: '/notifications',
      component: NotificationList,
      name: "NotificationList",
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/files',
      component: FileList,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/permissions',
      component: PlatformPermissions,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          await store.dispatch("programAuthz/apiGetAuthzUsers")
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/files/:context',
      component: FileList,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/dashboard',
      component: DashboardHome,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/billing',
      component: PlatformBilling,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/postings',
      component: PostingList,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/members',
      component: MembersList,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/operation-register',
      component: OperationRegister,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          await store.dispatch('apiGetRegisterOperation')
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/envelopes',
      component: EnvelopeList,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/envelopes/:uuid',
      component: Envelope,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          await store.dispatch('apiGetEnvelopeDetails', to.params.uuid)
          await store.dispatch('apiGetEnvelope', to.params.uuid)
          store.commit("setDisableNavigation", true)
          next()
        }

        updateToPlatformContext(callback)
      }
    },
    {
      path: '/assets',
      component: AssetList,
      name: "AssetList",
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/receipts',
      component: ReceiptList,
      name: "ReceiptList",
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/executions',
      component: ExecutionsList,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          await store.dispatch('apiGetExecutionsProgram')
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/executions/:id',
      component: ExecutionDetail,
      meta: {
        breadcrumb: {
          paths: [
            {
              path: '/executions',
              name: 'Lista de operações'
            },
            {
              path: '',
              name: 'Detalhes da operação'
            }
          ]
        }
      },
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          await store.dispatch('apiGetExecutionDetail', to.params.id)
          await store.dispatch('apiGetExecutionsProgram')
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/create-program-steps',
      component: CreateProgramSteps,
      name: "CreateProgramSteps",
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          store.commit("setDisableNavigation", true)
          store.commit("setUseActionFooter", true)
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/create-program-steps/:id',
      component: CreateProgramSteps,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          store.commit("setDisableNavigation", true)
          store.commit("setUseActionFooter", true)
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/operation-register/:id',
      name: 'Dados pessoais',
      component: OperationInfo,
      meta: {
        breadcrumb: {
          paths: [
            {
              path: '/operation-register',
              name: 'Lista de Operações'
            },
            {
              path: '',
              name: ''
            }
          ]
        }
      },
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          await store.dispatch('apiGetOperationInfoDiagram', to.params.id)
          store.commit('setCurrentOperationInfo', to.params.id)

          const breadcrumbPaths = to.meta.breadcrumb.paths
          const lastIndex = breadcrumbPaths.length - 1

          const currentRegisterOperation = store.getters.getCurrentRegisterOperation

          breadcrumbPaths[lastIndex].name = currentRegisterOperation
            ? currentRegisterOperation.description : '&nbsp;'

          next()
        }

        updateToProgramContext(callback)
      }
    },
    {
      path: '/platform-configure-program',
      component: PlatformConfigureProgram,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          store.commit("setDisableNavigation", true)
          store.commit("setUseActionFooter", true)
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/trades',
      component: TradeList,
      name: 'TradeList',
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/platform-organizations',
      component: PlatformOrganizationList,
      name: 'PlatformOrganizationList',
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          await store.dispatch(
            "platformOrganization/apiGetPlatformOrganizations");
          store.commit("setDisableNavigation", false)
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/platform-personal-data',
      component: PlatformPersonalData,
      name: 'PlatformPersonalData',
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/platform-home',
      component: PlatformHome,
      name: 'PlatformHome',
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          const accountId = store.state.auth.keycloak.tokenParsed['account-id'];
          await store.dispatch('apiGetAllPrograms', accountId)
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/platform-organization/:id',
      component: PlatformOrganization,
      children: [
        {
          path: '',
          component: OrganizationProfile,
          name: 'PlatformOrganization',
          meta: platformOrganizationBreadCrumb,
        },
        {
          path: 'profile',
          component: OrganizationProfile,
          meta: platformOrganizationBreadCrumb,
        },
        {
          path: 'organization-documents',
          component: DocumentTemplate,
          meta: {...platformOrganizationBreadCrumb, scope: 'organization'}
        },
        {
          path: 'program-documents',
          component: DocumentTemplate,
          meta: {...platformOrganizationBreadCrumb, scope: 'program'}
        },
        {
          path: 'partners',
          component: PartnersTemplate,
          meta: platformOrganizationBreadCrumb
        },
        {
          path: 'bank-account',
          component: BankAccounts,
          meta: platformOrganizationBreadCrumb,
        },
        {
          path: 'connection-configuration',
          component: ConnectionConfiguration,
          meta: platformOrganizationBreadCrumb,
        }
      ],
      props: true,
      meta: {
        breadcrumb: {
          paths: [
            {
              path: '/platform-organizations',
              name: 'Organizações'
            },
            {
              path: '',
              name: ''
            }
          ]
        }
      },
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          const {id} = to.params
          const organization = await store.dispatch(
            "platformOrganization/apiGetPlatformOrganization", id);
          await store.dispatch(
            "platformOrganization/apiGetPlatformOrganizationAddress", id);
          await store.dispatch(
            "platformOrganization/apiGetPlatformOrganizationContact", id);
          const breadcrumbPaths = to.meta.breadcrumb.paths
          const lastIndex = breadcrumbPaths.length - 1
          store.commit("setDisableNavigation", false)
          breadcrumbPaths[lastIndex].name = organization.data.name

          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '/platform-configuration',
      component: PlatformConfiguration,
      children: [
        {
          path: '',
          component: EnvelopeTemplateList,
          name: 'PlatformConfiguration'
        },
        {
          path: 'envelope-template',
          component: EnvelopeTemplateList,
        }
      ],
      props: true,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          const organization = await store.dispatch("platformConfiguration/apiGetEnvelopeTemplateList", {
            filter: '',
            page: 1,
            size: 5
          });
          store.commit("setDisableNavigation", false)
          next()
        }
        updateToPlatformContext(callback)
      }
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/500',
      name: 'Error500',
      component: Error500,
      props: store.state.originPath
    },
    {
      path: '/assume-role',
      component: AssumeProgramRole,
      name: 'AssumeRole',
      props: true,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/program-configuration',
      component: ConfigurationSchemaModal,
      name: 'ConfigurationSchemaModal',
      props: true,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToProgramContext(callback)
      }
    },
    {
      path: '/customers',
      component: PlatformCustomers,
      beforeEnter: (to, from, next) => {
        const callback = async () => {
          next()
        }
        updateToPlatformContext(callback)
      }
    }
  ]
})

async function pageInterface() {
  if (this.callback) {
    await this.callback()
  }

  const {
    theHeader: headerConfig,
    theNavigation: navigationConfig
  } = this.config
  const {
    theHeader: headerTheme,
    theNavigation: navigationTheme
  } = this.theme

  setTheHeaderState.apply({...headerConfig, ...headerTheme})
  setTheNavigationState.apply({...navigationConfig, ...navigationTheme})
}

function setTheHeaderState() {
  store.commit("setHeaderTitle", this.title);
  store.commit("setHeaderUseBoxShadow", this.useBoxShadow);
  store.commit("setHeaderBg", this.backgroundColor);
  store.commit("setHeaderExtraMenu", this.extraMenu);
}

function setTheNavigationState() {
  store.commit("setNavigationContentItens", this.menu)
  store.commit("setNavigationBg", this.backgroundColor)
  store.commit("setNavigationContext", this.navigationContext)
}

const firstHostName = window.location.hostname.split('.')[0]
const secondHostName = window.location.hostname.split('.')[1]

function updateToPlatformContext(callback = null) {

  if(firstHostName === 'finan4' || firstHostName === 'finanfor' &&
  (secondHostName === 'net' || secondHostName === 'app' || secondHostName === 'com') ||
  firstHostName === 'localhost') {

    store.commit("setProgramLogoId", null)
    document.body.classList.remove('v-tour--active')
    store.dispatch("getPlatformTour")
    if(!store.state.platformTour.platformTours){
      store.dispatch("createLocalStorageTour")
    }

    pageInterface.apply({
      config: platformConfig,
      theme: platformTheme,
      callback
    })
  } else window.location.replace(window.location.href.replace(`${firstHostName}.`, ''))
}

function updateToProgramContext(callback) {
  if(secondHostName === 'finan4' || secondHostName === 'finanfor'  || firstHostName === 'localhost') {

    store.dispatch('apiGetProgramRoles')
    store.dispatch('apiGetProgramRoleTypes')
    if (store.state.platformProgram.programLogoId == null){
      store.dispatch('apiGetProgramInfo')
    }

    pageInterface.apply({
      config: programConfig,
      theme: programTheme,
      callback
    })
  } else router.push('/platform-home')
}

export default router
