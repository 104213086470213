<template>
  <BaseContainer
    class="executions-list"
    :permission-list="['owner']"
  >
    <template v-slot:header-title>
      Operações {{ programName }}
    </template>
    <template>
      <v-chip
        class="ma-2 ml-0 font-weight-bold"
        label
        text-color="primary"
        color="secondary"
      >
        <v-icon
          left
          small
        >
          calendar_today
        </v-icon>
        Data das operações: {{ dateToday | getDate }}
      </v-chip>
    </template>
    <template>
      <v-row>
        <v-card
          v-for="(card, index) in cardsOperations"
          :key="index"
          :height="100"
          :width="300"
          class="card"
          :class=" {'card-dark' : card.color === 'primary'} "
          outlined
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title>
              <div>
                <h3
                  class="subtitle-2 font-weight-bold"
                  :class=" {'headline' : card.color !== 'primary'} "
                >
                  {{ card.title }}
                </h3>
                <div class="font-weight-bold mt-2 text-h5">
                  {{ card.value }}
                </div>
              </div>
            </v-card-title>
            <v-card-actions v-if="card.link">
              <BaseLink
                v-if="false"
                :to="card.link"
                class="mr-4 mt-8"
              >
                <v-icon :class=" {'card-dark' : card.color === 'primary'} ">
                  mdi mdi-export
                </v-icon>
              </BaseLink>
            </v-card-actions>
          </div>
        </v-card>
      </v-row>
    </template>
    <BaseDataTable
      :headers="headers"
      :items="executionsProgram.executions"
      background-color="transparent"
    >
      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="item in scopedItems"
          :key="item.index"
        >
          <td>
            {{ item.item.data && item.item.data.name ? item.item.data.name : item.item.description }}
          </td>
          <td width="53%">
            <v-progress-linear
              :value="getStatusProgress(item.item.status)"
              :color="getStepColor(item.item.status)"
              rounded
              height="25"
              :indeterminate="item.item.status === 'RUNNING'"
            >
              <template v-slot:default="{ value }">
                <p>{{ getStepText(item.item.status) }}</p>
                <p v-if="item.item.status !== 'RUNNING'">{{ Math.ceil(value) }}%</p>
              </template>
            </v-progress-linear>
          </td>
          <td class="text-right">
            <BaseButton
              :has-icon="true"
              class="ma-0"
              @click="$router.push({ path: `/executions/${item.item.id}`})"
            >
              <v-icon>mdi-export</v-icon>
            </BaseButton>
          </td>
        </tr>
      </template>
    </BaseDataTable>
  </BaseContainer>
</template>

<script>
import { mapState } from 'vuex'
import BaseDataTable from '../BaseDataTable'

export default {
  components: { BaseDataTable },
  data() {
    return {
      sumOfTotalAmount: 0,
      sumOfTotalWriteOff: 0,
      itemsFinished: 0,
      headers: [
        {
          text: 'Últimas operações',
          value: 'description',
          class: '',
          sortable: false
        },
        {
          hidden: false,
          text: 'Status',
          value: 'status',
          class: '',
          sortable: false
        },
        {
          hidden: false,
          text: 'Detalhes',
          value: 'id',
          class: '',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapState({
      programInfo: (state) => state.platformProgram.programInfo,
      executionsProgram: (state) => state.operationInfo.executionsProgram
    }),
    programName() {
      return this.programInfo ? this.programInfo.name : ''
    },
    dateToday() {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    totalItemsExecutions(){
      return this.executionsProgram && this.executionsProgram.totalItems ? this.executionsProgram.totalItems : 0
    },
    cardsOperations(){
      return [
        {
          title: "Operações finalizadas",
          value: `${this.itemsFinished} de ${this.totalItemsExecutions}`,
          color: "primary",
        },
        {
          title: "Valor baixado",
          value: this.$options.filters.formatCurrency(this.sumOfTotalWriteOff),
          link: "/",
        },
        {
          title: "Valor cedido",
          value: this.$options.filters.formatCurrency(this.sumOfTotalAmount),
          link: "/",
        }
      ]
    }
  },
  watch: {
    executionsProgram: {
      handler() {
        this.sumTotalExecutions()
      },
      deep: true,
    },
  },
  created(){
    this.sumTotalExecutions()
  },
  methods: {
    sumTotalExecutions(){
      this.sumOfTotalAmount = 0
      this.sumOfTotalWriteOff = 0
      this.itemsFinished = 0
      this.executionsProgram.executions.forEach(item => {
        if(item.data.total_amount){
          this.sumOfTotalAmount = this.sumOfTotalAmount + parseFloat(item.data.total_amount)
        }

        if(item.data.total_write_off){
          this.sumOfTotalWriteOff = this.sumOfTotalWriteOff + parseFloat(item.data.total_write_off)
        }

        if(item.status.toUpperCase() === "OK"){
          this.itemsFinished++
        }
      });
    },
    getStepText(status){
      const text = {
        "ok" : "Finalizado",
        "error" : "Alerta",
        "running" : "Em progresso",
        "pending" : "Não iniciado"
      }
      return text[status.toLowerCase()]
    },
    getStepColor(status){
      const color = {
        "ok": "#4CAF50",
        "error": "#EB6868",
        "running": "#FB8C00",
        "pending": "#00000061"
      }
      return color[status.toLowerCase()]
    },
    getStatusProgress(status){
      const progress = {
        "ok": 100,
        "error": 100,
        "running": 0,
        "pending": 0
      }
      return progress[status.toLowerCase()]
    }
  }
}
</script>

<style lang="scss">
  .executions-list {
    .card {
      border-radius: 8px;
      border: 1px solid #293273;
      color: #293273;
      margin: 20px 5px;
    }

    .card-dark {
      background-color: #293273;
      color: white;
    }
    .v-progress-linear--rounded {
      border-radius: 15px;
    }
    .v-progress-linear__content {
      justify-content: space-between !important;
      padding: 0 10px;
    }
    .v-progress-linear__indeterminate .long,
    .v-progress-linear__indeterminate .short {
      animation-duration: 10s;
    }
  }
</style>
